export const TARIFF_CONFIG_BY_TARIFF_TYPE = {
  single: 'singleTariff',
  variable: 'variableTariff',
  dynamic: 'dynamicTariff'
};

export const TARIFF_CONFIG_FOR_TABS = {
  single: 0,
  variable: 1,
  dynamic: 2
};

export const TARIFF_VARIABLE_STEP_COLORS = {
  lowTariff: '#1BBC9B',
  highTariff: '#F53920',
  standardTariff: '#7B87FF',
  tariff4: '#FFB822',
  tariff5: '#3998F9',
  tariff6: '#ED6200'
};

export const TARIFF_VARIABLE_STEP_INDEXES = {
  0: 'lowTariff',
  1: 'highTariff',
  2: 'standardTariff',
  3: 'tariff4',
  4: 'tariff5',
  5: 'tariff6'
};

export const TARIFFS_VARIABLE_PRICES_KEYS = Object.values(TARIFF_VARIABLE_STEP_INDEXES);

export const TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY = {
  gridFees: {
    Switzerland: 'Rp./kWh',
    Liechtenstein: 'Rp./kWh',
    Denmark: 'DKK/kWh',
    Default: 'ct/kWh'
  },
  taxesAndDutiesMonth: {
    Switzerland: 'CHF/month',
    Liechtenstein: 'CHF/month',
    Denmark: 'DKK/month',
    Default: 'EUR/month'
  },
  taxesAndDutiesKWh: {
    Switzerland: 'Rp./kWh',
    Liechtenstein: 'Rp./kWh',
    Denmark: 'DKK/kWh',
    Default: 'ct/kWh'
  },
  tariff: {
    Switzerland: 'Rp./kWh',
    Liechtenstein: 'Rp./kWh',
    Denmark: 'DKK/kWh',
    Default: 'ct/kWh'
  }
};

export const NUMBER_OF_TARIFF_STEPS = [2, 3, 4, 5, 6].map((value) => ({ value, label: value }));

export const TARIFF_TYPE_FORM_ID = 'TARIFF_TYPE_FORM_ID';
export const TAXES_AND_DUTIES_FORM_ID = 'TAXES_AND_DUTIES_FORM_ID';
export const GRID_FEES_FORM_ID = 'GRID_FEES_FORM_ID';

export const VARIABLE_DEFAULT_STEPS_CONFIG = {
  mondayFriday: [
    { tariffOption: 'lowTariff', fromTime: '00:00' },
    { tariffOption: 'highTariff', fromTime: '07:00' },
    { tariffOption: 'lowTariff', fromTime: '20:00' }
  ],
  saturday: [
    { tariffOption: 'lowTariff', fromTime: '00:00' },
    { tariffOption: 'highTariff', fromTime: '07:00' },
    { tariffOption: 'lowTariff', fromTime: '13:00' }
  ],
  sunday: [{ tariffOption: 'lowTariff', fromTime: '00:00' }]
};

export const directMarketing = [
  { label: 'noSolarTariff', value: 'standard' },
  { label: 'stockExchange', value: 'stockExchange' },
  { label: 'fix5', value: 'fixed5Years' },
  { label: 'fix10', value: 'fixed10Years' }
];

export const DIRECT_MARKETING_KEYS = {
  standard: 'noSolarTariff',
  stockExchange: 'stockExchange',
  fixed5Years: 'fix5',
  fixed10Years: 'fix10'
};

export const MAX_STEPS_COUNT = 7;

export const DEFAULT_PRICES_BY_COUNTRY_AND_TARIFF_TYPE = {
  'feed-in': {
    Switzerland: { tariff: 5.6 },
    Liechtenstein: { tariff: 8.11 },
    Denmark: { tariff: 0 },
    Germany: { tariff: 8.11 },
    Default: { tariff: 10 }
  },
  purchase: {
    Switzerland: { tariff: 29 },
    Liechtenstein: { tariff: 39.5 },
    Denmark: { tariff: 2.77 },
    Germany: { tariff: 39.5 },
    Default: { tariff: 28.9 }
  }
};
