import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import i18n from '../../../../../i18n';
import { TariffPrice } from '../../../../TariffSettings/components/TariffPrice';
import { DIRECT_MARKETING_KEYS, TARIFF_CONFIG_BY_TARIFF_TYPE, TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY } from '../../../../TariffSettings/constants';
import { getTaxesAndDutiesPrice } from '../../../../TariffSettings/utils';

const TariffDetailsCard = ({ title, link, tariffType, tariff, gridFees, taxesAndDuties, country, isAllowedToEdit, directMarketing }) => (
  <Box sx={{ width: '100%', gap: 0.75, display: 'flex', flexDirection: 'column' }}>
    <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h3" className="new-design typography-action text-base1 text-nowrap">
        {i18n.t(title)}
      </Typography>
      {isAllowedToEdit && (
        <Link to={link} className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill" title={i18n.t('editTooltip')}>
          <i className="la la-edit" />
        </Link>
      )}
    </Box>

    {/* Energy Tariff Section */}
    <Box className="new-design d-flex flex-column bg-secondary" sx={{ px: 2, py: 1.5, borderRadius: 2 }}>
      <Typography variant="h5" className="new-design typography-h5 text-base1" sx={{ mb: 0.5 }}>
        {i18n.t('energyTariff')}
      </Typography>
      <Box sx={{ display: 'grid', gridTemplateColumns: '0.4fr 1fr', gap: 1.5 }}>
        <Typography className="new-design typography-body1 text-accent2 text-nowrap">{i18n.t('Tariff type')}</Typography>
        <Typography className="new-design typography-body1 text-base1 text-nowrap">{(directMarketing && i18n.t(DIRECT_MARKETING_KEYS[directMarketing])) || tariffType}</Typography>
        {!directMarketing && (
          <TariffPrice
            tariff={{ ...tariff || {}, stepsCount: tariff?.numberOfActiveTariffs, tariffType, directMarketing }}
            tariffPriceCurrency={TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY.tariff[country] || TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY.tariff.Default}
          />
        )}
      </Box>
    </Box>

    {/* Grid Fees Section */}
    {gridFees?.fixed || gridFees?.variable ? (
      <Box className="new-design d-flex flex-column bg-secondary" sx={{ px: 2, py: 1.5, borderRadius: 2 }}>
        <Typography variant="h5" className="new-design typography-h5 text-base1" sx={{ mb: 0.5 }}>
          {i18n.t('gridFees')}
        </Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: '0.4fr 1fr', gap: 1.5 }}>
          <Typography className="new-design typography-body1 text-accent2 text-nowrap">{i18n.t('feeType')}</Typography>
          <Typography className="new-design typography-body1 text-base1 text-nowrap">{i18n.t(gridFees.fixed ? 'fixedFee' : 'variableFee')}</Typography>
          <TariffPrice
            tariff={{
              price: gridFees.fixed,
              prices: gridFees.variable?.prices,
              stepsCount: gridFees.variable?.numberOfActiveTariffs
            }}
            tariffPriceCurrency={TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY.gridFees[country] || TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY.gridFees.Default}
          />
        </Box>
      </Box>
    ) : null}

    {/* Taxes & Duties Section */}
    {(taxesAndDuties?.kWh || taxesAndDuties?.month) ? (
      <Box className="new-design d-flex flex-column bg-secondary" sx={{ px: 2, py: 1.5, borderRadius: 2 }}>
        <Typography variant="h5" className="new-design typography-h5 text-base1" sx={{ mb: 0.5 }}>
          {i18n.t('taxesAndDuties')}
        </Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: '0.4fr 1fr', gap: 1.5 }}>
          <TariffPrice tariff={{ price: getTaxesAndDutiesPrice(taxesAndDuties, country) }} />
        </Box>
      </Box>
    ) : null}
  </Box>
);

export const TariffSection = ({ user, myRoleType, isAllowedToEdit }) => {
  const { tariffSettings: { purchase = {}, feedIn = {} } = {}, _id } = user;
  const isEndUser = myRoleType === 'end_user';

  if (!_id) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', gap: 4, width: '100%' }}>
      <TariffDetailsCard
        title="myPurchaseTariff"
        tariffType={purchase.tariffType}
        isAllowedToEdit={isAllowedToEdit}
        link={isEndUser ? `/tariff-settings/purchase/${purchase._id}` : `/users/${_id}/tariff-settings/purchase/${purchase._id}`}
        taxesAndDuties={purchase.taxesAndDuties}
        gridFees={purchase.gridFees}
        tariff={purchase[TARIFF_CONFIG_BY_TARIFF_TYPE[purchase.tariffType]] || {}}
        country={purchase?.country}
      />
      <TariffDetailsCard
        title="myFeedInTariff"
        tariffType={feedIn.tariffType}
        directMarketing={feedIn.directMarketing}
        isAllowedToEdit={isAllowedToEdit}
        link={isEndUser ? `/tariff-settings/feed-in/${feedIn._id}` : `/users/${_id}/tariff-settings/feed-in/${feedIn._id}`}
        taxesAndDuties={feedIn.taxesAndDuties}
        gridFees={feedIn.gridFees}
        tariff={feedIn[TARIFF_CONFIG_BY_TARIFF_TYPE[feedIn.tariffType]] || {}}
        country={feedIn?.country}
      />
    </Box>
  );
};

TariffSection.propTypes = {
  user: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string.isRequired,
  isAllowedToEdit: PropTypes.bool
};

TariffDetailsCard.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  tariffType: PropTypes.string,
  tariff: PropTypes.string,
  gridFees: PropTypes.string,
  taxesAndDuties: PropTypes.string,
  country: PropTypes.string,
  isAllowedToEdit: PropTypes.bool,
  directMarketing: PropTypes.string
};
